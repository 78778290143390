<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="pages_data"
      :items-per-page="10"
      item-key="PageNumber"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
      :dense="$vuetify.breakpoint.mdAndDown"
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat color="grey lighten-3">
          <v-btn outlined color="info" class="mr-2" dark>
            <v-icon left>
              mdi-hexagon-slice-6
            </v-icon>
            3 ตัวตรง
          </v-btn>

          <v-divider class="mx-4 info" inset vertical></v-divider>

          <v-text-field
            label=""
            placeholder="ใส่ตัวเลขที่ต้องการค้นหา"
            solo
            flat
            rounded
            hide-details
            class="shrink"
            dense
          ></v-text-field>
        </v-toolbar>
        <v-alert dense text class="text-center" type="success">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ pages_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'green lighten-4': item.PageNumber === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td align="end" :class="id_column">{{ item.PageNumber }}.</td>
          <td align="end">
            {{ add_comma(item.PriceSum) }}
          </td>
          <td align="end">
            {{ add_comma(item.PriceSumLoy) }}
          </td>
          <td align="center" :class="getStatusColor(item.Status)">
            {{ getStatusText(item.Status) }}
          </td>
          <td align="end">
            {{ add_comma(item.WinSum) }}
          </td>
          <td align="end">
            {{ add_comma(item.WinSumLoy) }}
          </td>
          <td align="center">
            {{ item.EditBy }}
          </td>
          <td align="start">
            {{ getDT(item.UpdateDT) }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import PopupMenu from "@/components/Cus/Pages/PageList/Popup_Menu";

export default {
  name: "PageList_Datatable",
  mixins: [globalFunctionMixin],

  components: {
    PopupMenu,
  },
  created() {
    //โหลด ตาราง
    this.LineID = this.$route.query.line_id;
    this.AgentID = this.$route.query.agent_id;
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    pages_data() {
      return this.$store.getters["pages/Pages"];
    },
    pages_count() {
      return this.$store.getters["pages/Pages"].length;
    },
    pages_detail() {
      return this.$store.getters["pages/PagesDetail"];
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        ListType: "page_list",
      };
      await this.$store.dispatch("pages/get_list", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    EnterPage(PageNumber) {
      this.$router.push({
        path: "page",
        query: {
          line_id: this.LineID,
          agent_id: this.AgentID,
          page_number: PageNumber,
        },
      });
    },
    row_click: function(item) {
      this.selectedId = item.PageNumber;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.SetAgentID(this.AgentID);
        this.$refs.PopupMenu1.SetLineID(this.LineID);
        this.$refs.PopupMenu1.Show(item.PageNumber);
        this.selectedId = item.PageNumber;
        //console.log(item);
      });
    },
  },
  data: () => ({
    LineID: "",
    AgentID: "",

    selectedId: -1,

    printing: false,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,

    headers: [
      {
        text: "รหัส",
        value: "PageNumber",
        align: "center",
        width: "10%",
      },
      {
        text: "ชื่อ",
        value: "BetSum",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "เบอร์โทร",
        value: "BetLoy",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
        // sortable: false,
      },
      {
        text: "บรรทัด",
        value: "Status",
        align: "center",
        width: "15%",
        class: "brown lighten-5",
      },
      {
        text: "ยอดแทง",
        value: "WinSum",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
    ],
    id_column: "id_column_class",
    id_column_sum: "id_column_sum_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: rgb(39, 109, 37) !important;
  color: whitesmoke;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 4%;
  left: 0;
}
::v-deep .id_column_sum_class {
  background-color: rgb(10, 107, 35) !important;
  color: whitesmoke;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 4%;
  left: 0;
}
/* ::v-deep table {
  table-layout : fixed;
} */

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(39, 109, 37) !important;
  color: whitesmoke !important;
  padding-top: 15px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: rgb(255, 255, 255);
  /* font-size: 1.0vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 18px !important;
  /* font-size: 110% !important; */
}

::v-deep table {
  background-color: #eceff1;
}

::v-deep tr:nth-child(odd) {
  background-color: #edfafc;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 1em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}

/* /deep/ tr.v-data-table__selected {
    background: lightgray !important;
    color: black;
    font-weight: bold;
  } */
</style>
