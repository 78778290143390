<template>
  <div class="Export_Page">
    <v-app-bar dense fixed app color="blue darken-4" dark>
      <v-btn to="/menu_data" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>ค้นหาตัวซื้อ</v-toolbar-title>
      <v-spacer></v-spacer>
      <strong class="white--text"
        >ถิง
        <v-icon color="pink lighten-4" class="ml-2 mr-2"
          >mdi-tab-search</v-icon
        ></strong
      >
      <strong class="white--text"> 10 ม.ค. 62</strong>
    </v-app-bar>
    <v-card>
      <v-card-text>
        <FindBetDatatable></FindBetDatatable>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import FindBetDatatable from "@/components/Data/Find_Bet/Find_Bet/Datatable";

export default {
  name: "Export_Pages",
  mixins: [globalFunctionMixin],
  components: {
    FindBetDatatable,
    // HpSplitView,
    // HpViewAll,
  },

  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
  },

  data: () => ({}),
};
</script>

<style scoped></style>
